// $background-main: #1f2228;
$background-second: #17191d;
$grey: #41454b;
$yellow: #ffe91c;
$green: #38A512;
$green-opacity: #394630;
$green-opacity: #474A45;
$red: #F02D2D;
$red-opacity: #4A4545;
$red-opacity: #4e3737;
$red-opacity: #4e3f3f;
$light-blue: #CCEAF6;
$blue: #0990F2;
$blue-lighter: #00ade2;



// NEW
$background-main: #1F2228;
// $background-dark: #0D0D0D;
$background-dark: #141414;
$light-grey: #353A44;

$shillcoin-background: #1f2228;
$shillcoin-footer: #17191d;

$blue: #2081E2;
