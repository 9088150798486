.add-collection-container {
  width: 100%;
  max-width: 440px;
  padding-left: 25px;
  padding-right: 25px;

  h1.title {
    margin-top: 35px;
    margin-bottom: 35px;
    text-align: center;
  }
  .inser-opensea-link-text {
    font-size: 19px;
    margin-top: 53px;
    text-align: center;
  }
  input {
    width: 100%;
    padding: 10px 20px;
    border-radius: 6px;
    background-color: #353A44;
    color: white;
    font-family: var(--main-font);
    font-size: 22px;
    font-size: 18px;
    line-height: 26px;
    border: 0;
    margin-top: 25px;
  }

  .submit-bttn {
    background-color: $yellow;
    color: black;
    font-size: 22px;
    font-weight: bold;
    font-family: var(--main-font-mono);
    cursor: pointer;
    margin-bottom: 50px;
  }

  .added-collection-card {
    margin-top: 61px;
    border: 2px solid rgba(255,255,255,0.5);
    border-radius: 14px;
    text-align: center;
    max-width: 180px;
    padding: 30px;
    margin: 0 auto;
    margin-bottom: 55px;
  }
  .image-container {
    position: relative;
    width: 120px;
    height: 120px;
    margin: 0 auto;
  }
  .verified-icon {
    position: absolute;
    top: 77px;
    right: -10px;
  }
  .added-collection-avatar {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .collection-name {
    margin-top: 14px;
    margin-bottom: 6px;
  }
}

.main-bttn {
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  padding: 14px 42px;
  font-size: 1.4rem;
  font-family: var(--main-font-mono);
  font-weight: 700;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 22px;
  font-family: var(--main-font-mono) !important;
  text-decoration: none !important;
  &:hover {
    transform: scale(1.05);
  }
}
.bttn-primary {
  color: black !important;
  a {
    color: black !important;
    font-family: var(--main-font-mono) !important;
    text-decoration: none !important;
  }
  background-color: $yellow;
}
.bttn-secondary {
  font-weight: 100;
  font-size: 21px;
}
