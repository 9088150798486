.donate-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 0;

  h1 {
    font-weight: bold;
    font-family: var(--main-font-mono);
    font-size: 2rem;
    color: white;
    margin-bottom: 24px;
  }

  h2 {
    font-family: var(--main-font);
    font-weight: bold;
    font-size: 22px;
    letter-spacing: 0.05rem;
    margin-top: 48px;
    text-align: center;
    line-height: 1.7;
  }

  .coin-switches {
    margin-top: 8px;
  }
  .coin-switch-container {
    display:inline-block;
    padding: 5px 10px;
    font-size: 16px;
    font-family: var(--main-font);
    background-color: #292F3A;
    margin: 4px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: $grey;
      color: white;
    }
  }
  .coin-switch-container.selected {
    background: $yellow;
    color: black;
  }

  .donate-text {
    font-family: var(--main-font);
    margin-bottom: 38px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    line-height: 28px;
  }

  /*
   * PULSING HEART CSS ANIMATION
   */
  #wrapper-pulsing-heart {
    animation: pulse 1s infinite;
    margin-bottom: 0;
    display: inline-block;
    margin-left: 10px;
  }
  svg.heart-svg path {
    animation: pulsecolor 1s infinite;
    fill: $yellow;
    margin-top: 20px;
  }
  #pulsingheart {
    position: relative;
    width: 20px;
    height: 18px;
  }
  @keyframes pulse {
    10% {
      transform: scale(1.3);
    }
  }
  @keyframes pulsecolor {
    10% {
      fill: #fff38b;
    }
  }
  a.buymeacoffe-button-container:hover {
    box-shadow: 0 0 10px $yellow;
    border-radius: 8px;
  }
  #buymeacoffe-button {
    margin-bottom: 0;
  }

  // coin donation card ("ticket")
  .coin-donation-card {
    position: relative;
    margin-top: 38px;
    width: 190px;
    height: 320px;
    background-color: $light-blue;
    border-radius: 12px;
    .circle {
      width: 33px;
      height: 33px;
      background-color: $background-main;
      border-radius: 50%;
      position: absolute;
      top: 49px;
    }
    .circle.left {
      left: -16px;
    }
    .circle.right {
      right: -16px;
    }
    hr.dashed-line {
      border:none;
      border-top:3px dashed $background-main;
      height:1px;
      width:100%;
      position: absolute;
      top: 63px;
    }
    .coin-donation-card-title {
      width: 100%;
      height: 65px;
      h1 {
        font-family: var(--main-font);
        font-weight: bold;
        font-size: 26px;
        color: black !important;
        margin-bottom: 6px;
        letter-spacing: 0;
      }
    }

    .qr-wrapper {
      position: absolute;
      top: 75px;
      width: 190px;
      height: 190px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .address-wrapper {
      position: absolute;
      bottom: 0px;
      width: 190px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 14px;
        overflow-wrap: break-word;
        max-width: 190px;
        padding-left: 11px;
        padding-right: 11px;
        text-align: center;
        color: black;
        font-family: var(--main-font-mono);
      }
    }
    #copy-to-clipboard-hidden-input {
      position: absolute;
      z-index: -1000;
      top: 20px;
      margin-left: 10px;
      width: 170px;
    }
  }
  .tap-to-copy {
    cursor: pointer;
    padding: 17px;
    text-align: center;
    height: 60px;
  }
}

// fix for iphone 5
@media (max-width: 350px) {
  .donate-container .coin-switch-container {
    padding: 4px 8px !important;
    font-size: 14px !important;
    margin: 2px !important;
  }
}
