:root {
  --main-font: "Ubuntu", monospace;
  --main-font-mono: "Ubuntu Mono", monospace;
}

* {
  margin: 0;
  box-sizing: border-box;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

html {
  height: 100%;
}

body {
  background-color: $shillcoin-background;
  color: white;
  font-family: var(--main-font);
}

.main-content {
  display:flex;
  justify-content: center;
  width: 100vw;
  max-width: 1080px;
  margin: 0 auto;
}

.non-footer-content {
  // position: absolute; // for the background-clippath element as anchor
  width: 100%;
}
.flex-wrapper-for-footer {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

// Basic Styling
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.transparent {
  background: transparent !important;
}
.pointer {
  cursor: pointer;
}
a, a:hover, a:active, a:visited, a:focus {
    text-decoration:none;
}
.remove-all-link-styling,
.remove-all-link-styling:visited,
.remove-all-link-styling:active {
  outline: 0;
  text-decoration: none;
  color: white;
}
.pos-relative {
  position: relative;
}
.pos-absolute {
  position: absolute;
}
.text-elipsis { // wrap text
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
// remove selection and borders on click
// https://stackoverflow.com/a/21003770/6272061
.no-select {
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.yellow-text {
  color: $yellow;
}

.bold-text {
  font-weight: bold;
}

.larger-text {
  font-size: 19px;
}
