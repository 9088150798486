.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 0;
  h1,
  h3 {
    font-family: var(--main-font-mono);
  }

  h1 {
    font-size: 2rem;
    color: white;
    letter-spacing: 0.14rem;
    margin-bottom: 32px;
    text-align: center;
  }

  .faq-list {
    max-width: 900px;
    margin: 0 29px;

    h3 {
      margin-bottom: 8px;
    }

    .faq-answer {
      margin-bottom: 24px;
      line-height: 1.4;
      text-align: justify;
    }
    span.answer-text {
      opacity: 0.6;
    }
  }
  .link-to-donate {
    color: rgba(255,255,255,0.6);
    &:hover {
      // font-weight: bold;
      color: $yellow;
    }
  }
}
