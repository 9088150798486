.terms {
  width: calc(min(100vw, 800px));

  .terms-header {
    background-color: #1f2228;
    background-image: radial-gradient(#b7b7b712 1px, #1f2228 1px);
    background-size: 20px 20px;
    padding: 60px 1rem;
    border-bottom: 1px solid #80808021;
    .terms-intro {
      text-align: center;
      h4 {
        color: white;
        // font-family: $bold-font;
        font-size: 50px;
        margin: 1rem;
      }
      #info {
        max-width: 70%;
        margin: 0 auto;
        line-height: 150%;
        // font-family: $thin-font;
        color: white;
        font-size: 1.1rem;
      }
    }
  }

  .legal-navigation {
    padding: 85px 0;
    padding-bottom: 0;
  }

  .terms-navigation-line {
    height: 1px;
    background-color: #66666626;
    width: 100%;
    margin: 0 auto;
  }

  .terms-navigation {
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: center;
    position: relative;
    top: -25px;
    width: 85%;
    margin: 0 auto;
    background-color: #1f2228;
    border-radius: 25px;
    border: 1px solid #66666626;
    justify-content: space-around;
    margin-bottom: 40px;
    p {
      margin: 0 !important;
      padding-left: 15px;
      padding-right: 15px;
      color: #cdc9c9 !important;
      opacity: 0.5;
    }
    p.selected {
      opacity: 1;
      color: white;
    }
    p.terms-navigation-item {
      cursor: pointer;
      // width: 20%;
    }

    a {
      text-decoration: none;
      color: inherit;
    }
  }
  .legal-content {
    padding: 0 15px;
    max-width: 700px;
    margin: 0 auto;
    z-index:0;
    margin-top: 55px;
    h1 {
      // font-family: $bold-font;
      // color: #ffe91cb3;
    }
    h2 {
      // font-family: $bold-font;
      // color: #ffe91cb3;
      opacity: 0.8;
    }
    p {
      // font-family: $thin-font;
      font-size: 1rem;
      color: #cdc9c9;
      line-height: 1.6rem;
      // margin-bottom: 10px;
    }
    a {
      text-decoration: underline;
      color: #cdc9c9;
    }
    ul, li {
      // font-family: $thin-font;
      font-size: 1rem;
      color: #cdc9c9;
      line-height: 1.7rem;
    }
    .terms-intro {
      border-bottom: 0.5px dotted #666666;
      margin-bottom: 25px;
      h3 {
        // font-family: $bold-font;
        color: #ffe91cb3;
        font-size: 22px;
      }
    }
    .terms-inline-bold {
      // font-family: $bold-font;
    }
    .terms-inline-nomargin {
      margin: 0;
    }
  }

  .terms-main-section {
      margin-top: 55px;
      h2 {
        margin: 25px 0 10px 0;
      }
      h3 {
        // font-family: $bold-font;
        color: #ffe91cb3;
        font-size: 24px;
        margin: 25px 0 10px 0;
      }
      h4 {
        // font-family: $bold-font;
        color: #ffe91cb3;
        font-size: 20px;
        margin: 25px 0 10px 0;
      }
      h5 {
        // font-family: $bold-font;
        color: #ffe91cb3;
        font-size: 17px;
        opacity: 0.9;
        margin: 25px 0 10px 0;
      }
      h6 {
        // font-family: $bold-font;
        color: #ffe91cb3;
        font-size: 15px;
        opacity: 0.8;
        margin: 25px 0 10px 0;
      }
    }

  @media screen and (min-width:680px) {
    .terms-navigation {
      width: 60%;
    }
  }

  .max-width-impressum {
    max-width: 750px !important;
    margin: 0 auto;
  }
  .impressum-content {
    // padding: 0 2.5vh;
    padding: 0 1rem;
    margin-bottom: 100px;
    .impressum-split {
      display: flex;
      flex-direction: column;
      // align-items: center;
      text-align: center;
      margin: 50px 0;
      .split {
        width: 100%;
        // padding: 0 2.5vh;
        padding: 0 1rem;
      }
    }
    .impressum-content-block {
      padding: 10px 0;
      h3 {
        // font-family: $bold-font;
        font-weight: 1.3rem;
        line-height: 130%;
        // color: $default-blue;
        margin-bottom: 0.3rem;
      }
      p {
        line-height: 170%;
        // font-family: $thin-font;
        // color: $default-blue;
        font-size: 1rem;
      }
    }
  }

  @media screen and (min-width:680px) {
    .impressum-split {
      flex-direction: row !important;
      text-align: center !important;
    }
    .split {
      padding: 0 !important;
    }
  }

}
