.dashboard-container {
  width: calc(min(100vw, 500px));
  text-align: center;
  padding: 8px;
  .title {
    font-size: 35px;
    text-align: center;
    margin: 20px;
    margin-bottom: 30px;
  }
  p.alert-text {
    margin-bottom: 12px;
    font-size: 18px;
    font-family: var(--main-font-mono);
  }
  .green {
    color: lightgreen;
    font-weight: bold;
  }
  .red {
    color: coral;
    font-weight: bold;
  }
  .dashboard-new-alert-bttn {
    margin-top: 58px !important;
  }
  .dashboard-unsubscribe-bttn {
    cursor: pointer;
    background-color: #41454b !important;
    color: #fff !important;
    font-size: 18px !important;
    border-radius: 8px !important;
    border: none !important;
    padding: 10px 20px !important;
    font-family: var(--main-font-mono) !important;
  }
}
