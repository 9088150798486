.footer {
  background-color: $shillcoin-footer;
  min-height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  font-size: 16px;

  .contact-icon {
    font-size: 30px;
    cursor: pointer;
  }
  .heart svg {
    margin: 0 6px;
    color: $yellow;
  }
  .contact-icon svg {
    margin: 0 2px;
    color: $grey;
    &:hover {
      color: $yellow;
    }
  }
  .contact-text {
    font-weight: bold;
    margin-left: 20px;
    margin-right: 6px;
    line-height: 28px;
  }
  .personal-website-link {
    margin-left: 5px;
    margin-right: 5px;
    font-weight: bold;
    &:hover {
      color: $yellow;
    }
  }
}
