
.create-alert-form-container {
  // flex: 1 1;
  // width: 100%;
  // max-width: 500px;
  // text-align: center;

  form {
    width: 322px;
    max-width: 100vw;
    margin: 0 auto;

    input {
      width: 100%;
      padding: 10px 20px;
      border-radius: 6px;
      background-color: #353A44;
      color: white;
      font-family: var(--main-font);
      font-size: 22px;
      font-size: 18px;
      line-height: 26px;
      border: 0;
      margin-top: 25px;
    }
    input:not(.submit-bttn) {
      padding-left: 60px;
    }
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
    input:invalid {
      box-shadow: 0 0 4px rgb(255, 95, 95);
    }
    input.email-input {
      font-size: 18px;
      line-height: 18px;
      height: 46px;
    }
    .input-icon-left {
      position: absolute;
      top: 40px;
      left: 15px;
      color: rgba(255,255,255,0.4);
    }
    .selected-nft-thumbnail {
      position: absolute;
      top: 29px;
      left: 4px;
      border-radius: 50%;
      width: 38px;
      height: 38px;
    }
    .vertical-line {
      width: 1px;
      height: 46px;
      background-color: rgba(255,255,255,0.1);
      position: absolute;
      left: 46px;
      top: 25px;
    }
    .submit-bttn {
      background-color: $yellow;
      color: black;
      font-size: 22px;
      font-weight: bold;
      font-family: var(--main-font-mono);
      cursor: pointer;
      margin-bottom: 50px;
      &.disabled {
        background-color: rgba(0,0,0,0.6);
        color: rgba(255,255,255,0.4);
        cursor: not-allowed;
      }
    }
  }

  .alert-preview-text {
    margin: 20px;
    max-width: 322px;
    color: rgba(255,255,255,0.8);
    font-size: 16px;
    line-height: 23px;
    font-family: var(--main-font);
    margin: 0 auto;
    margin-top: 25px;
    text-align: center;
    .highlighted {
      font-weight: bold;
      color: $yellow;
    }
  }

  .results-container {
    position: absolute;
    top: 72px;
    left: 5px;
    background-color: white;
    width: 100%;
    max-width: 314px;
    z-index: 2000;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
    color: #090909;
    cursor: pointer;
    max-height: 500px;
    overflow: auto;

    .result-item {
      &:hover {
        background-color: rgb(240, 240, 240);
      }
      padding: 12px;
      border-bottom: 1px solid rgba(0,0,0,0.1);
      position: relative;
      .collection-thumbnail {
        position: absolute;
        top: 2px;
        left: 3px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
      }
      .is-verified-badge {
        position: absolute;
        top: 23px;
        left: 31px;
        color: #2081E2;
      }
      .collection-name {
        text-align: left;
        margin-left: 45px;
      }
    }
  }

  .add-extra-margin {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .collection-missing-text {
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 12px;
  }
  .add-missing-collection-bttn {
    background-color: #2081E2;
    color: white;
    padding: 8px 16px;
    text-align: center;
    max-width: 150px;
    margin: 0 auto;
    // margin-top: 20px;
    border-radius: 8px;
    margin-bottom: 12px;
    box-shadow: 0 0 14px rgba(0,0,0,0.2);
    &:hover {
      transform: scale(1.1);
    }
  }
}


.loading-text-container {
  text-align: left;
  position: absolute;
  z-index: 200;
  right: 11px;
  top: 10px;
  #loading-text {
    color: rgba(255,255,255,0.6);
    font-size: 13px;
  }
}

/**
 * LOADING SPINNER1
 * => https://tobiasahlin.com/spinkit/
 */
.spinner-tobiasahlin {
  width: 10px;
  height: 10px;
  position: relative;
  margin: 0;
}
.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: lightcoral;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}
.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}
@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

/**
 * LOADING SPINNER2
 */
.spinner-black {
  // width: 70px;
  // text-align: center;
  margin-left: 10px;
}
.spinner-black > div {
  width: 10px;
  height: 10px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner-black .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spinner-black .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}


/**
 * TOP MARGIN
 */
.create-alert-form-container {
  margin-top: 60px;
}
@media (max-width: 1000px) {
  .create-alert-form-container {
    margin-top: 0px;
  }
}

.no-results-subtitle {
  padding: 6px;
}

.create-alert-bttn {
  width: 100%;
  padding: 10px 20px;
  border-radius: 6px;
  // background-color: #353A44;
  // color: white;
  font-family: var(--main-font);
  font-size: 22px;
  font-size: 18px;
  line-height: 26px;
  border: 0;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-micro-modal--portal,
.react-micro-modal--portal * {
  z-index: 1000000;
}
