
.background-clippath-right {
  // background-color: green;
  background-color: $background-main;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top:0;
  z-index: -100;
  clip-path: polygon(55% 0, 100% 0, 100% 100%, 45% 100%);
}
.background-clippath-left {
  background-color: $background-dark;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top:0;
  z-index: -100;
  clip-path: polygon(55% 0, 0 0, 0 100%, 45% 100%);
}
