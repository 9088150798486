// fixed nav on mobile
.nav-wrapper-sticky {
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 10000;
  background: $background-main;
  transition: box-shadow 0.1s ease;
}
.box-shadow-on-scroll {
  box-shadow: 0 -2px 10px rgba(0,0,0,0.8);
}
.nav-height-placeholder{
  margin-top: 71px;
}

nav.mobile {
  display: flex;
  flex-direction: column;

  .nav-menu {
    padding: 16px;
    display: flex;

    .nav-info {
      display: flex;
      flex-grow: 1;
      align-items: center;

      a {
        display: flex;
        align-items: center;
        color: white;
        text-decoration: none;
      }
      .nav-logo {
        width: 40px;
        height: 40px;
        margin-right: 16px;
        img {
          width: 100%;
        }
      }

      .nav-title {
        font-family: var(--main-font-mono);
        font-size: 1.3rem;
        letter-spacing: 0.05rem;
        font-weight: bold;
      }
    }

    .nav-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 6px;
      font-size: 2.5rem;

      &:hover {
        cursor: pointer;
        color: $yellow;
      }
    }
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    padding: 0;
    z-index: 1;
    background-color: $background-main;
    position: fixed;
    padding-top: 36px;
    max-width: 0%;
    width: 0;
    height: calc(100vh - 72px);
    top: 72px;
    right: 0;
    transition: all 0.3s;

    svg.heart-svg {
      width: 19px;
      margin-left: 10px;
      fill: white;
    }

    /*
     * fix to pass github deploy checks:
     * create button element with onclick trigger to
     * prevent empty href value in <a> tag
     */
    button {
      background-color: $background-main;
      border:none;
      cursor: pointer;
      outline: none;
      padding-left: 0;
      padding-right:0;
    }
    a, button{
      color: white;
      text-decoration: none;
      margin-bottom: 32px;
      font-size: 1.7rem;
      font-family: var(--main-font-mono);
      width: max-content;
      &:hover {
        color: $yellow;
        * {
          // for nested svg element (heart)
          fill: $yellow;
        }
      }

      svg {
        font-size: 1.2rem;
        color: $yellow;
        margin-bottom: -2px;
      }
    }
  }

  .nav-links.show {
    max-width: 100%;
    width: 100%;
    padding: 36px 32px;
    visibility: visible;
    height: 100%;
  }
}

@media (min-width: 768px) {
  .nav {
    .nav-links {
      transition: all 0.2s ease-in-out;
      height: calc(100vh - 72px - 64px);
    }
    .nav-links.show {
      max-width: 100%;
      width: 250px;
      padding: 36px 32px;
      visibility: visible;
    }
  }
  // fix hamburger menu on desktop
  .nav-icon{
    margin-right: 20px;
  }
}



// NAVBAR DESKTOP
nav.desktop {
  max-width: 1080px;
  margin: 10px auto;
  height: 72px;
  // background-color: $background-main;
  background-color: transparent;
  margin: 0 auto;
  overflow: hidden;
  font-family: var(--main-font-mono);
  font-size: 18px;

  .nav-title {
    font-size: 22px;
    font-weight: bold;
  }
  .nav-logo,
  .nav-logo > img {
    width: 40px;
    height: 100%;
    margin-right: 10px;
  }
  button.last-item {
    margin-right: 27px;
  }
  ul {
    height: 72px;
    // margin: 0 auto;
    padding: 0;
    display: flex;
    li {
      &.scaled {
        transform: scale(1.2);
        font-weight: bold;
      }
      &.yellow * {
        color: $yellow;
      }
      display: inline-block;
      list-style-type: none;
      &.scale-on-hover:hover {
        font-weight: bold;
        transform: scale(1.2);
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        // background-color: $background-main;
        background-color: transparent;
        line-height: 3;
        padding: 10px 30px;
        height: 72px;
        text-decoration: none;
      }
    }
    li:first-child {
      margin-right: auto;
    }
  }
  // navbar button to submit video
  button#submit-video-bttn {
    cursor: pointer;
    background-color: $grey;
    color: white;
    font-size: 18px;
    border-radius: 8px;
    border: none;
    padding: 10px 20px;
    font-family: var(--main-font-mono);
    &.active {
      font-weight: bold;
      background-color: $yellow;
      color: black;
    }
    &:hover {
      font-weight: bold;
      background-color: $yellow;
      color: black;
    }
  }
}




// HAMBURGER ANIMATION
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  opacity: 0.7;
  // padding: 15px 15px;
  padding-right: 5px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #fff;
}
.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: 0px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 2px;
  background-color: #fff;
  border-radius: 2px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease; }
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block; }
.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -10px;
}
/*
 * Squeeze
 */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
