
.center-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto !important;
  margin-top: 20px !important;
}

.shillcoin-bttn {
  background-color: #ffe91c;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  padding: 14px 42px;
  font-size: 1.4rem;
  font-family: var(--main-font-mono);
  font-weight: 700;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 22px;
  a {
    color: black !important;
    font-family: var(--main-font-mono) !important;
    text-decoration: none !important;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.pending-double-opt-in {
  // margin-top: 40px;
  padding: 20px;
  border-radius: 12px;
  background-color: rgb(255, 190, 190);
  background-color: coral;
  color: black;
  max-width: 500px;
  margin: 0 auto;
}

img.warn-emoticon {
  width: 40px;
  height: 40px;
}

.error404-title.alerts {
  font-size: 35px !important;
  padding-left: 10px;
  padding-right: 10px;
}



@media (max-width: 500px) {
  .alerts .larger-text {
    font-size: 17px;
  }
}
@media (max-width: 400px) {
  .alerts .larger-text {
    font-size: 16px;
  }
  .error404-title.alerts {
    font-size: 29px !important;
  }
}

