// .nft-row {
//   display: inline-block;
//   padding: 20px 20px;
//   width: 150px;
//   height: 200px;
//   text-align: center;
//   font-family: var(--main-font-mono);
//   border: 2px solid rgb(194, 194, 194);
//   border-radius: 26px;
//   margin: 10px;

//   img.avatar {
//     border-radius: 50%;
//     width: 100px;
//     height: 100px;
//     cursor: pointer;
//     margin-top: 5px;
//     margin-bottom: 5px;
//   }
//   .price {
//     font-size: 22px;
//   }
//   .title {
//     font-size: 18px;
//   }
// }

.nft-container {
  max-width: 1030px;
  margin: 0 auto;
  margin-top: 40px;
  h1 {
    text-align: center;
  }
  p {
    text-align: center;
    font-size: 20px;
  }
  // margin-left: 10px;
  // margin-right: 10px;
}


// SEE ALL VIDEOS BUTTON IN THE BOTTOM,
// only shows when a keyword is selected
.basic-bttn {
  margin-top: 18px;
  margin-bottom: 40px;
  background-color: $yellow;
  color: black;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  font-family: var(--main-font-mono);
  padding: 8px 16px;
  border-radius: 8px;

  &:hover {
    transform: scale(1.05);
  }
}


// new table layout
.nft-row {
  display: flex;
  img.avatar {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin: 2px;
    margin-right: 15px;
  }
  .text-row {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    line-height: 55px;
    max-width: 280px;
  }
  .collection-name {
    max-width: 200px;
  }
}


/*
 * Spinner animation for loading
 * source https://tobiasahlin.com/spinkit/
 */
.spinner {
  margin: 0 auto 0;
  margin-top: 20px;
  width: 70px;
  text-align: center;
}
.spinner > div {
  width: 18px;
  height: 18px;
  background-color: $yellow;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
